<template>
  <div style="height: 48px">
    <v-toolbar
      dense
      color="warning"
      flat
      style="position: fixed; width: 100%; z-index: 9999"
    >
      <v-spacer />
      <v-toolbar-title class="white--text title-font font-weight-regular">
        Cancellation Scheduled
      </v-toolbar-title>
      <v-spacer />
    </v-toolbar>
  </div>
</template>

<script>
export default {
  name: "AppBar"
}
</script>
