import { axiosClient } from "@/plugins/axios"
import clientOptions from "@/plugins/axios/client_options"

export default class OftAPI {
  constructor() {
    this.country = null
    this.fileProgress = []
    this.totalFileSize = 0
    this.progressCallback = null
  }

  setCountry(country) {
    this.country = country
  }

  async uploadFiles(files, progressCallback = null) {
    if (!files) return

    const isArray = Array.isArray(files)
    if (!isArray) files = [files]

    if (files.length === 0) return

    // Initialize progress tracking
    if (progressCallback !== null) {
      this._initializeProgress(files)
      this.progressCallback = progressCallback
    }

    // Prepare files array with extra metadata
    const fileUploads = files.map((file) => ({
      file,
      oftFileName: null,
      oftUid: null,
      uploadLink: null,
      uploaded: false
    }))

    const oftResponse = await this._sendToOft(fileUploads).catch(() => {
      return
    })

    // Update file metadata with oft response
    fileUploads.forEach((file, index) => {
      file.oftFileName = oftResponse[index].fileName
      file.uploadLink = oftResponse[index].oneTimeUploadLink
      file.oftUid = oftResponse[index].uid
    })

    // Prepare upload promises. Pass in progress update method.
    const uploadPromises = fileUploads.map((file, index) =>
      this._uploadToGcs(
        file.file,
        file.uploadLink,
        index,
        this._updateProgress.bind(this)
      ).then(() => {
        file.uploaded = true
      })
    )

    await Promise.allSettled(uploadPromises)

    return isArray ? fileUploads : fileUploads[0]
  }

  async getFileUrls(files) {
    if (!files) return

    const isArray = Array.isArray(files)
    if (!isArray) files = [files]

    if (files.length === 0) return

    const oftFileUids = files.map((file) => file.oft_file_uid)

    let response
    const params = {
      uid: oftFileUids
    }

    try {
      response = await axiosClient.get(clientOptions.oftURL, { params })
    } catch (error) {
      console.error(
        `Error fetching file URLs from OFT with params: ${params.uid}`,
        error
      )
      throw error
    }

    files.forEach((file) => {
      const oftFile = response.data.find(
        (item) => item.uid === file.oft_file_uid
      )
      if (oftFile) {
        file.url = oftFile.oneTimeDownloadLink
      }
    })

    return isArray ? files : files[0]
  }

  async _sendToOft(files) {
    const payload = files.map((file) => {
      return {
        fileName: file.file.name
      }
    })
    try {
      const response = await axiosClient.post(
        `${clientOptions.oftURL}/upload/${this.country}`,
        payload
      )
      return response.data
    } catch (error) {
      console.error("Failed to add files to OFT:", error)
      throw error
    }
  }

  async _uploadToGcs(file, url, index, updateProgress) {
    try {
      const response = await axiosClient.put(url, file, {
        headers: {
          "Content-Type": file.type || "application/octet-stream",
          Authorization: undefined
        },
        onUploadProgress: (progressEvent) => {
          if (this.progressCallback !== null)
            updateProgress(index, progressEvent.loaded)
        }
      })
      return response
    } catch (error) {
      console.error(`Error uploading file ${file.name}:`, error)
      throw error
    }
  }

  _initializeProgress(files) {
    this.fileProgress = new Array(files.length).fill(0)
    this.totalFileSize = files.reduce((sum, file) => sum + file.size, 0)
  }

  _resetProgress() {
    this.fileProgress = []
    this.totalFileSize = 0
    this.progressCallback = null
  }

  _updateProgress(index, loaded) {
    this.fileProgress[index] = loaded

    const totalUploaded = this.fileProgress.reduce(
      (sum, progress) => sum + progress,
      0
    )

    const totalProgress = Math.min(
      Math.round((totalUploaded / this.totalFileSize) * 100),
      100
    )

    this.progressCallback(totalProgress)
  }
}
