<template>
  <div>
    <v-btn
      block
      outlined
      color="error"
      class="rounded-10"
      @click="dialog = true"
    >
      Request Changes
    </v-btn>
    <v-dialog v-model="dialog" max-width="600px" content-class="rounded-20">
      <ChangesCompose
        :approval="approval"
        :approve="false"
        @close="dialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import client from "@/lib/ApiClient"
import ChangesCompose from "./shared/ChangesCompose.vue"
export default {
  props: ["approval"],
  components: { ChangesCompose },
  data() {
    return {
      loading: false,
      dialog: false
    }
  },

  watch: {
    orderApprovals() {
      if (this.loading) {
        this.loading = false
        this.dialog = false
      }
    }
  },

  computed: {
    ...mapGetters(["orderApprovals"])
  },

  methods: {
    ...mapActions(["triggerReload"]),
    async requestChanges() {
      this.loading = true
      await client.instance.messages.approvalAction(
        this.approval.uid,
        "request_changes"
      )
      this.triggerReload("approvals")
      this.triggerReload("order")
      this.triggerReload("inboxItems")
    }
  }
}
</script>
