<template>
  <v-card flat class="card-bg rounded-20" v-if="subscription">
    <v-toolbar class="transparent mb-3" flat dense>
      <v-toolbar-title class="title-font"> PLAN & BILLING </v-toolbar-title>
    </v-toolbar>
    <v-card flat class="mx-4 rounded-10">
      <v-row>
        <v-col>
          <v-list>
            <!-- TODO: Activation of subscription upon first transaction does not update the Subscription Info, implement reload. -->
            <v-list-item v-for="(item, index) in details" :key="index" two-line>
              <v-list-item-content>
                <v-list-item-title class="title-font">
                  {{ item.key.toUpperCase() }}:
                </v-list-item-title>
                <v-list-item-subtitle class="black--text">
                  {{ item.value }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col v-if="subscriptionSchedule">
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="title-font">
                  SCHEDULED PLAN:
                </v-list-item-title>
                <v-list-item-subtitle class="black--text">
                  {{ subscriptionSchedule }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-card>
    <v-card-actions class="ma-2 mt-3">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="subscription.has_schedule ? on : null">
            <v-btn
              @click="cancelDialog = true"
              text
              class="rounded-10"
              :disabled="subscription.has_schedule"
            >
              {{
                subscription.auto_renew
                  ? "Schedule Cancellation"
                  : "Re-enable Renewal"
              }}
            </v-btn>
          </div>
        </template>
        <span> A subscription change is scheduled. </span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="subscription.auto_renew ? null : on">
            <v-btn
              @click="changeDialog = true"
              :disabled="!subscription.auto_renew"
              class="btn-primary"
              text
            >
              Change Subscription
            </v-btn>
          </div>
        </template>
        <span> A subscription cancellation is scheduled. </span>
      </v-tooltip>
    </v-card-actions>
    <CancelDialog v-model="cancelDialog" />
    <ChangeDialog
      v-if="subscription.auto_renew"
      v-model="changeDialog"
      :suggesting="suggesting"
    />
  </v-card>
</template>

<script>
import { capitalise } from "@/utils"
import { mapGetters } from "vuex"
import ChangeDialog from "./SubscriptionInfo/ChangeDialog"
import CancelDialog from "./SubscriptionInfo/CancelDialog"

export default {
  name: "SubscriptionInfo",
  components: {
    CancelDialog,
    ChangeDialog
  },

  data() {
    return {
      cancelDialog: false,
      changeDialog: false,
      suggesting: false
    }
  },

  watch: {
    changeDialog(value) {
      if (this.suggesting && value === false)
        setTimeout(() => (this.suggesting = false), 300)
    }
  },

  computed: {
    ...mapGetters(["subscription", "subscriptionSchedule"]),

    details() {
      const subscriptionStatus = this.subscription.activated
        ? "Active"
        : "In Grace Period"
      return [
        {
          key: "Current Plan",
          value: this.subscription.plan_code
        },
        {
          key: "Subscription Status",
          value: subscriptionStatus
        },
        {
          key: "Renew Frequency",
          value: capitalise(this.subscription.renewal_frequency)
        },
        {
          key: "Next Payment Due",
          value: new Date(this.subscription.end_time).toLocaleDateString()
        }
      ]
    }
  },

  mounted() {
    if (this.$route.params.change) {
      this.suggesting = true
      this.changeDialog = true
    }
  }
}
</script>
