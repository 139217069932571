<template>
  <div>
    <v-form ref="orderForm" lazy-validation>
      <v-row>
        <v-col cols="6">
          <ConnectionTypeSelector
            v-model="localForwardedOrderForm.order_form.connection_type"
          />
          <MultiUnitConfirmation
            v-model="localForwardedOrderForm.implants"
            :interactive="true"
          />
          <SpecialRequests v-model="localForwardedOrderForm.special_requests" />
        </v-col>
        <v-col cols="6">
          <UploadProjectFiles v-model="files" />
          <v-row>
            <v-col>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  class="rounded-10 btn-error"
                  elevation="0"
                  @click="$emit('back')"
                >
                  Go Back
                </v-btn>
                <v-btn
                  class="btn-primary my-4 mx-4"
                  @click="handleContinue"
                  :disabled="!isOwner && !designsAvailable"
                >
                  Send Order
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>

    <PurchaseDialog
      v-model="dialog"
      :item="lineItem"
      :acquisitionMethod="acquisitionMethod"
      :loading="loading"
      @confirm="handleConfirm"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { deepCopy } from "@/utils"
import client from "@/lib/ApiClient"
import MultiUnitConfirmation from "@/components/shared/MultiUnitConfirmation.vue"
import PurchaseDialog from "@/components/shared/PurchaseDialog.vue"
import ConnectionTypeSelector from "./ForwardedOrderOptions/ConnectionTypeSelector.vue"
import UploadProjectFiles from "./ForwardedOrderOptions/UploadProjectFiles.vue"
import SpecialRequests from "@/components/shared/SpecialRequests.vue"

export default {
  name: "ForwardedOrderOptions",

  components: {
    PurchaseDialog,
    ConnectionTypeSelector,
    MultiUnitConfirmation,
    UploadProjectFiles,
    SpecialRequests
  },

  data() {
    return {
      dialog: false,
      loading: false,
      acquisitionStrategy: null,
      localForwardedOrderForm: {},
      files: []
    }
  },

  watch: {
    localForwardedOrderForm: {
      async handler(value) {
        if (!value || !this.selectedForwardedOrder) return
        const response = await client.instance.orders.updateForwardedOrder(
          this.selectedOrder.uid,
          this.selectedForwardedOrder.uid,
          value
        )
        this.setSelectedForwardedOrder(response)
      },
      deep: true
    },
    files: {
      handler(value) {
        this.localForwardedOrderForm.file_uids = value
          .filter((item) => item !== null)
          .map((item) => item.uid)
      },
      deep: true
    }
  },

  computed: {
    ...mapGetters([
      "isOwner",
      "country",
      "products",
      "currentPlan",
      "currentQuotas",
      "selectedOrder",
      "selectedForwardedOrder",
      "forwardedOrderForm"
    ]),

    lineItem() {
      const product = this.products.find((p) => p.product_code === "DESIGN")
      return {
        description: "Design Only",
        quantity: 1,
        currency: this.currentPlan?.subscription_currency,
        price: product?.price,
        product_code: "DESIGN",
        quota_code: "designs"
      }
    },

    acquisitionMethod() {
      return this.acquisitionStrategy?.find((s) => s.product_code === "DESIGN")
        .method
    },

    designsAvailable() {
      const designs = this.currentQuotas.find((q) => q.quota_code === "designs")
      return designs.current
    }
  },

  methods: {
    ...mapActions([
      "setCurrentQuotas",
      "triggerReload",
      "setSelectedForwardedOrder"
    ]),

    async handleContinue() {
      if (!this.$refs.orderForm.validate()) return
      this.loading = true
      const purchaseForm = {
        items: [
          {
            product_code: "DESIGN",
            quantity: 1
          }
        ],
        payment_type: "stripe",
        order_uid: this.selectedOrder.uid
      }
      this.acquisitionStrategy = await client.gateway.billing.checkStrategy(
        purchaseForm
      )
      this.loading = false
      this.dialog = true
    },

    async handleConfirm(paymentMethodId) {
      this.loading = true
      const purchaseForm = {
        items: [
          {
            product_code: "DESIGN",
            quantity: 1
          }
        ],
        payment_type: "stripe",
        payment_id: paymentMethodId,
        order_uid: this.selectedOrder.uid,
        metadata: {
          forwarded_order_uid: this.selectedForwardedOrder.uid
        }
      }
      try {
        await client.gateway.billing.commitPurchase(purchaseForm)
      } catch {
        // Error States?
        console.error("Failed to order Tooth Design")
        this.loading = false
        this.dialog = false
        return
      }

      if (this.acquisitionMethod === "quota") {
        this.triggerReload("quotas")
      }
      this.triggerReload("forwardedOrder")
      this.triggerReload("forwardedOrders")
      this.triggerReload("order")
      this.loading = false
      this.dialog = false
      this.$emit("next")
    }
  },

  beforeMount() {
    this.localForwardedOrderForm = deepCopy(this.forwardedOrderForm)
  }
}
</script>
