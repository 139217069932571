<template>
  <div>
    <v-select
      v-model="selectedStatuses"
      multiple
      :items="statuses"
      outlined
      hide-details
      :menu-props="{ rounded: '10' }"
    >
      <template v-slot:item="{ item }">
        <v-icon small class="mr-2">{{ item.icon }}</v-icon>
        {{ item.text }}
      </template>
      <template v-slot:selection="{ item }">
        <v-chip
          :outlined="!['waiting'].includes(item.value)"
          :color="item.value === 'message' ? 'success' : null"
          small
          :class="[
            'mr-1',
            { 'notification-warning': item.value === 'waiting' }
          ]"
        >
          <v-icon small class="mr-2">{{ item.icon }}</v-icon>
          {{ item.text }}
        </v-chip>
      </template>
    </v-select>
  </div>
</template>

<script>
import client from "@/lib/ApiClient"
import { mapActions, mapGetters } from "vuex"

export default {
  name: "StatusComponent",

  data() {
    return {
      statuses: [
        { text: "INBOX", icon: "$inboxreceived", value: "inbox" },
        {
          text: "IMPLANTS EXPORTED",
          icon: "$implantexport",
          value: "exported"
        },
        { text: "AWAITING RESPONSE", icon: "$alarm", value: "waiting" },
        { text: "DESIGN ONLY", icon: "$toothdesigns", value: "design" },
        { text: "MILLED BARS", icon: "$milledbar", value: "bar" }
      ]
    }
  },

  computed: {
    ...mapGetters(["selectedOrder"]),

    selectedStatuses: {
      get() {
        return this.selectedOrder.statuses
      },
      async set(value) {
        if (value.length === 0) return
        await client.instance.orders.changeOrderStatuses(
          this.selectedOrder.uid,
          value
        )
        this.triggerReload("order")
        this.triggerReload("inboxItems")
      }
    }
  },

  methods: {
    ...mapActions(["triggerReload"])
  }
}
</script>
