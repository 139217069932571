<template>
  <v-card>
    <v-card-title
      >IMPORTANT:
      {{ approve ? "APPROVE WITH CHANGES" : "REQUEST CHANGES" }}</v-card-title
    >
    <v-card-text>
      <p v-if="approve">
        Your case will continue with the minor changes requested. <br />
        <strong> Do not continue if you wish to see another 3D design </strong>
        for review.
      </p>
      <p v-if="!approve">
        You will receive another design for approval before the case continues
        to production. This is
        <strong>recommended for all major changes.</strong>
      </p>

      <v-row>
        <v-col>
          <input
            type="file"
            ref="fileInput"
            style="display: none"
            multiple
            @change="handleFileUpload"
          />
          Select message type:
          <v-btn-toggle
            v-model="visibility"
            class="rounded-20"
            elevation="0"
            disabled
          >
            <v-btn small value="LAB_AND_OSTEON" v-if="selectedOrder.forwarded">
              <v-img
                contain
                width="16"
                class="mr-1"
                :src="require('@/assets/osteon-logo.png')"
              />
              To Osteon
            </v-btn>
          </v-btn-toggle>
          <v-textarea
            v-if="visibility"
            v-model="note"
            class="rounded-10 mt-2"
            hide-details
            outlined
            dense
            rows="1"
            :placeholder="placeholder"
            auto-grow
            prepend-inner-icon="mdi-paperclip"
            @click:prepend-inner="openFileInput"
          >
            <!-- <template v-slot:append>
              <v-btn
                small
                icon
                :loading="sendingMessage"
                @click="sendMessage()"
              >
                <v-icon v-if="visibility === 'LAB_ONLY'">
                  mdi-content-save-outline
                </v-icon>
                <v-icon v-if="visibility !== 'LAB_ONLY'">mdi-send</v-icon>
              </v-btn>
            </template> -->
          </v-textarea>
        </v-col>
        <v-col v-if="attachments.length > 0" cols="12" align="end">
          <v-chip
            small
            v-for="(attachment, index) in attachments"
            :key="index"
            close
            class="ma-1 rounded-10"
            @click:close="removeAttachment(index)"
          >
            <v-icon small>mdi-paperclip</v-icon>
            <span class="text-truncate">
              {{ attachment.name }}
            </span>
          </v-chip>
        </v-col>
        <v-col cols="12" class="pt-1 pb-0" v-if="visibility">
          <v-alert
            dense
            outlined
            color="warning"
            class="rounded-20"
            v-if="visibility === 'LAB_AND_OSTEON'"
          >
            <v-icon small color="warning">mdi-alert-outline</v-icon>
            Visible to {{ currentTenant.name }} and Osteon
          </v-alert>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        class="btn-primary"
        @click="sendMessageAndApprovalAction"
        :loading="sendingMessage"
        >{{ approve ? "Approve and Send" : "Request Changes" }}</v-btn
      >
      <v-btn elevation="0" class="rounded-10" @click="$emit('close')">
        Cancel
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import client from "@/lib/ApiClient"

export default {
  props: ["approval", "approve"],

  data() {
    return {
      sendingMessage: false,
      note: "",
      attachments: [],
      failedUploads: [],
      visibility: "LAB_AND_OSTEON"
    }
  },

  watch: {
    orderApprovals() {
      if (this.sendingMessage && this.approve) {
        this.sendingMessage = false
        this.reset()
        this.$emit("close")
      }
    },
    orderMessages() {
      if (this.sendingMessage && !this.approve) {
        this.sendingMessage = false
        this.reset()
        this.$emit("close")
      }
    }
  },

  computed: {
    ...mapGetters([
      "currentTenant",
      "selectedOrder",
      "orderMessages",
      "country"
    ]),

    placeholder() {
      // if (this.visibility === "LAB_ONLY") return "Internal Note"
      // if (this.visibility === "LAB_AND_DENTIST") return "Message to Client"
      return "Message to Osteon"
    }
  },

  methods: {
    ...mapActions(["triggerReload"]),
    async sendMessageAndApprovalAction() {
      if (!this.note.length) {
        return
      }

      this.sendingMessage = true

      const message = {
        approval_uid: this.approval.uid,
        oep_component_uid: this.approval.oep_component_uid,
        direction: "OUTBOUND",
        visibility: this.visibility,
        author_reference: this.currentTenant.name,
        recipient_reference: "",
        note: this.note
      }

      const promises = []

      if (this.attachments.length) {
        const uploadedFiles = await client.oft.uploadFiles(this.attachments)

        const payload = []
        uploadedFiles.forEach((file) => {
          if (file.uploaded) {
            payload.push({
              file_name: file.oftFileName,
              oft_file_uid: file.oftUid
            })
          } else this.failedUploads.push(file)
        })

        message.attachments = payload
      }

      promises.push(
        client.instance.messages.sendMessage(this.selectedOrder.uid, message)
      )

      promises.push(
        client.instance.messages.approvalAction(
          this.approval.uid,
          this.approve ? "approve_with_changes" : "request_changes"
        )
      )
      await Promise.all(promises)

      this.triggerReload("messages")
      this.triggerReload("approvals")
    },

    removeAttachment(index) {
      this.attachments.splice(index, 1)
    },

    reset() {
      this.note = ""
      this.attachments = []
      this.failedAttachments = []
    },

    openFileInput() {
      this.$refs.fileInput.click()
    },

    handleFileUpload(event) {
      const files = [...event.target.files]
      files.forEach((file) => {
        this.attachments.push(file)
      })
    }
  }
}
</script>

<style>
.v-textarea.v-text-field--box.v-text-field--single-line.v-input--dense
  .v-input__prepend-inner,
.v-textarea.v-text-field--box.v-text-field--single-line.v-input--dense
  .v-input__prepend-outer,
.v-textarea.v-text-field--box.v-text-field--single-line.v-input--dense
  .v-input__append-inner,
.v-textarea.v-text-field--box.v-text-field--single-line.v-input--dense
  .v-input__append-outer,
.v-textarea.v-text-field--box.v-text-field--outlined.v-input--dense
  .v-input__prepend-inner,
.v-textarea.v-text-field--box.v-text-field--outlined.v-input--dense
  .v-input__prepend-outer,
.v-textarea.v-text-field--box.v-text-field--outlined.v-input--dense
  .v-input__append-inner,
.v-textarea.v-text-field--box.v-text-field--outlined.v-input--dense
  .v-input__append-outer,
.v-textarea.v-text-field--enclosed.v-text-field--single-line.v-input--dense
  .v-input__prepend-inner,
.v-textarea.v-text-field--enclosed.v-text-field--single-line.v-input--dense
  .v-input__prepend-outer,
.v-textarea.v-text-field--enclosed.v-text-field--single-line.v-input--dense
  .v-input__append-inner,
.v-textarea.v-text-field--enclosed.v-text-field--single-line.v-input--dense
  .v-input__append-outer,
.v-textarea.v-text-field--enclosed.v-text-field--outlined.v-input--dense
  .v-input__prepend-inner,
.v-textarea.v-text-field--enclosed.v-text-field--outlined.v-input--dense
  .v-input__prepend-outer,
.v-textarea.v-text-field--enclosed.v-text-field--outlined.v-input--dense
  .v-input__append-inner,
.v-textarea.v-text-field--enclosed.v-text-field--outlined.v-input--dense
  .v-input__append-outer {
  margin-top: 5px !important;
}
</style>
