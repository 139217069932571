<template>
  <v-container fluid>
    <v-row fill-height>
      <v-col cols="8" md="10" sm="12" offset="2" offset-md="1" offset-sm="0">
        <v-row>
          <v-col cols="12">
            <div>
              <h1 class="title-h1">
                Your gateway to digital
                <br />
                full-arch restorations
              </h1>
            </div>
          </v-col>
          <v-col class="pa-3">
            <v-stepper
              v-model="step"
              style="box-shadow: none"
              class="rounded-20"
            >
              <v-stepper-items>
                <v-card flat class="card-bg pa-4">
                  <v-card-title class="pa-0">
                    <h2 class="title-h2 primary--text">
                      {{ step === 4 ? "Confirm Purchase" : "Sign Up" }}
                    </h2>
                  </v-card-title>
                  <v-card-text class="pa-0 mb-2">
                    {{
                      step === 3
                        ? "Choose your required starter options"
                        : "Create a Nexus Connect account to get started."
                    }}
                  </v-card-text>
                  <v-stepper-content step="1" class="pa-0">
                    <v-card flat class="rounded-10">
                      <v-card-title class="title-font">
                        <v-icon class="mr-2">mdi-information</v-icon>
                        Business Information
                      </v-card-title>
                      <BusinessInformation
                        v-if="!loading"
                        v-model="tenantInformation"
                        @next="nextStep()"
                      />
                    </v-card>
                  </v-stepper-content>
                  <v-stepper-content step="2" class="pa-0">
                    <ServiceInformation
                      v-model="tenantInformation"
                      @previous="previousStep()"
                      @next="nextStep()"
                    />
                  </v-stepper-content>
                  <v-stepper-content step="3" class="pa-0">
                    <v-card flat class="rounded-10">
                      <v-card-title class="pb-0">
                        <v-icon class="mr-2">mdi-plus</v-icon>
                        Add optional products
                      </v-card-title>
                      <v-card-text>
                        <div
                          v-for="(product, index) in [
                            scanGaugeKit
                            // v HIDDEN for demo. What is this product?
                            // labValidationKit
                          ]"
                          :key="index"
                        >
                          <v-checkbox
                            hide-details
                            v-model="additionalProducts"
                            :value="product.code"
                          >
                            <template slot="label">
                              {{ product.name }} - ${{
                                product.price?.toLocaleString()
                              }}
                            </template>
                          </v-checkbox>
                        </div>
                      </v-card-text>
                      <v-card-text class="d-flex">
                        <v-btn
                          class="btn-primary-light rounded-10"
                          @click="previousStep()"
                          text
                        >
                          Back
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                          class="btn-primary rounded-10"
                          @click="createTenant"
                          text
                        >
                          Continue
                        </v-btn>
                      </v-card-text>
                    </v-card>
                  </v-stepper-content>
                  <v-stepper-content step="4" class="pa-0">
                    <v-card flat class="rounded-10">
                      <v-card-text>
                        <v-row v-if="!stripeReady" class="stripe-loader">
                          <v-col cols="12">
                            <v-skeleton-loader type="text" />
                          </v-col>
                          <v-col cols="6">
                            <v-skeleton-loader type="text" />
                          </v-col>
                          <v-col cols="6">
                            <v-skeleton-loader type="text" />
                          </v-col>
                          <v-col cols="12">
                            <v-skeleton-loader type="text" />
                          </v-col>
                          <v-col cols="12" class="text-center"
                            >Loading Stripe Payment...</v-col
                          >
                        </v-row>
                        <StripeElementPayment
                          v-if="stripeReady"
                          ref="paymentRef"
                          :pk="pk"
                          :elements-options="elementsOptions"
                          :confirm-params="confirmParams"
                          @error="handlePaymentError"
                        />
                      </v-card-text>
                    </v-card>

                    <v-card-text class="d-flex px-0 pb-0">
                      <v-btn
                        class="btn-primary-light rounded-10"
                        @click="abandonConfirmation"
                        :disabled="loading"
                        text
                      >
                        Cancel
                      </v-btn>
                      <v-spacer />
                      <v-btn
                        v-if="stripeReady"
                        :loading="loading"
                        class="btn-primary rounded-10"
                        text
                        @click="pay"
                        >Pay Now &amp; Subscribe</v-btn
                      >
                    </v-card-text>
                  </v-stepper-content>
                </v-card>
              </v-stepper-items>
            </v-stepper>
          </v-col>
          <v-col class="pa-3">
            <v-card flat class="card-bg rounded-20 pa-4" v-if="step !== 4">
              <div>
                <v-card-title class="pa-0 mb-2">
                  <h2 class="title-h2 primary--text">Your Chosen Plan</h2>
                </v-card-title>
                <PricingCard
                  :pricing="planPricing"
                  :returnButton="true"
                  :step="step"
                />
              </div>
              <div
                v-if="
                  step === 3 && additionalProducts.includes('GAUGEKIT-GEN2')
                "
              >
                <v-card-title class="px-1">
                  <h2 class="title-h2 primary--text">Scan Gauge Kit</h2>
                </v-card-title>
                <ScanGaugeKit :scan-gauge-kit="scanGaugeKit" />
              </div>
              <div
                v-if="step === 3 && additionalProducts.includes('VALIDATION')"
              >
                <v-card-title class="px-1">
                  <h2 class="title-h2 primary--text">
                    Lab Validation Kit (Optional)
                  </h2>
                </v-card-title>
                <LabValidationKit :lab-validation-kit="labValidationKit" />
              </div>
            </v-card>
            <OrderSummary v-if="step === 4" :pricings="orderSummaryPricings" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog
      max-width="500px"
      persistent
      v-model="planMismatchDialog"
      content-class="rounded-20"
    >
      <v-card>
        <v-card-title class="off mb-2">
          <h2 class="title-h2">Plan Not Available</h2>
        </v-card-title>
        <v-card-text>
          <p>The plan you have selected is not available in your region.</p>
          <p>Please select another plan and try again.</p>
        </v-card-text>
        <v-card-actions class="off">
          <v-spacer />
          <v-btn :to="{ name: 'pricingPage' }" class="btn-primary">
            Choose New Plan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import BusinessInformation from "./RegistrationPage/BusinessInformation.vue"
import ServiceInformation from "./RegistrationPage/ServiceInformation.vue"
import OrderSummary from "./RegistrationPage/OrderSummary.vue"
import ScanGaugeKit from "./RegistrationPage/ScanGaugeKit.vue"
import LabValidationKit from "./RegistrationPage/LabValidationKit.vue"
import PricingCard from "./PricingPage/PricingCard.vue"

import client from "@/lib/ApiClient"

import { mapGetters, mapActions } from "vuex"
import { StripeElementPayment } from "@vue-stripe/vue-stripe"
import { deepValid, toDashCase } from "@/utils"

export default {
  name: "RegistrationPage",

  props: ["planCode"],

  components: {
    BusinessInformation,
    ServiceInformation,
    PricingCard,
    StripeElementPayment,
    OrderSummary,
    ScanGaugeKit,
    LabValidationKit
  },

  data() {
    return {
      loading: false,
      planMismatchDialog: false,
      submitted: false,
      step: 1,
      selectedDataStorage: null,
      selectedKit: null,
      tenantInformation: {
        instance_uid: null,
        slug: null,
        sameShippingAddress: true,
        name: null,
        phone_number: null,
        email_address: null,
        billing_address_1: null,
        billing_address_2: null,
        billing_suburb: null,
        billing_state: null,
        billing_postal_code: null,
        billing_country: null
      },
      subscribeForm: {
        tenant_uid: null,
        plan_code: this.planCode,
        country_code: null,
        auto_renew: true,
        payment_type: "stripe",
        extras: []
      },
      pk: process.env.VUE_APP_STRIPE_PK,
      elementsOptions: {
        clientSecret: null,
        appearance: {
          variables: { borderRadius: "10px" }
        }
      },
      confirmParams: {
        return_url: process.env.VUE_APP_STRIPE_POSTPAY_REDIRECT
      },
      additionalProducts: ["GAUGEKIT-GEN2"],

      labValidationKit: {
        code: "VALIDATION",
        name: "Lab Validation Kit",
        description: "Design & Overlay Checking Pack",
        currency: "AUD",
        price: 500,
        features: [
          "95mm x 20mm Milling Disk",
          "Placeholder feature",
          "Placeholder feature",
          "Placeholder feature"
        ]
      }
    }
  },

  watch: {
    planCode(value) {
      if (value) {
        this.fetchProducts(value)
      }
    },

    tenantInformation: {
      async handler(value) {
        if (this.country != value.billing_country) {
          this.setCountry(value.billing_country)
          const plansResponse = await client.gateway.plans.fetchAllPlans(
            this.country
          )
          this.setPlans(plansResponse)
          if (!this.plans.find((p) => p.plan_code === this.planCode)) {
            this.planMismatchDialog = true
          }
        }
      },
      deep: true
    }
  },

  computed: {
    ...mapGetters([
      "currentTenant",
      "plans",
      "products",
      "subscription",
      "country"
    ]),

    scanGaugeKit() {
      const product = this.products.find(
        (p) => p.product_code === "GAUGEKIT-GEN2"
      )
      return {
        code: "GAUGEKIT-GEN2",
        name: "Scan Gauge Kit",
        description: "Generation 2.0",
        currency: this.planPricing?.subscription_currency,
        price: product?.price,
        features: [
          "18 Gen 2.0 Scan Gauges",
          "3 Gen 2.0 Narrow Scan Bodies",
          "4 Gen 2.0 Scan Analogs",
          "8 Screws",
          "Driver & Handle",
          "Autoclavable Gen 2.0 Casette"
        ]
      }
    },

    stripeReady() {
      return !!this.elementsOptions.clientSecret
    },

    planPricing() {
      return this.plans.find((plan) => plan.code === this.planCode)
    },

    orderSummaryPricings() {
      let pricingItems = []
      if (this.additionalProducts.includes("VALIDATION")) {
        pricingItems.push({
          name: this.labValidationKit.name,
          currency: this.labValidationKit.currency,
          price: this.labValidationKit.price
        })
      }
      if (this.additionalProducts.includes("GAUGEKIT-GEN2")) {
        pricingItems.push({
          name: this.scanGaugeKit.name,
          currency: this.scanGaugeKit.currency,
          price: this.scanGaugeKit.price
        })
      }
      pricingItems.push({
        name: this.planPricing.name,
        currency: this.planPricing.subscription_currency,
        price: this.planPricing.subscription_price,
        renewal_frequency: this.planPricing.renewal_frequency
      })

      return pricingItems
    }
  },

  methods: {
    ...mapActions([
      "setSubscription",
      "setCurrentTenant",
      "setCountry",
      "setPlans",
      "fetchProducts"
    ]),

    nextStep() {
      switch (this.step) {
        case 1:
          if (!this.tenantInformation.slug && this.tenantInformation.name) {
            this.tenantInformation.slug = toDashCase(
              this.tenantInformation.name
            )
          }
          this.step = 2
          break
        case 2:
          this.step = 3
          break
      }
    },

    previousStep() {
      if (this.step <= 1) return
      this.step--
    },

    unloadGuard() {
      if (this.elementsOptions.clientSecret && !this.submitted) {
        this.abandonConfirmation()
      }
    },

    async pay() {
      this.loading = true
      this.submitted = true
      this.$refs.paymentRef.submit()
    },

    handlePaymentError() {
      this.loading = false
      this.submitted = false
    },

    async createTenant() {
      if (
        !deepValid(this.tenantInformation, [
          "shipping_state",
          "billing_state",
          "billing_address_2",
          "shipping_address_2"
        ])
      )
        return console.error("Invalid Form")
      this.subscribe()
      this.step = 4
    },

    async subscribe() {
      this.loading = true
      try {
        if (this.tenantInformation.sameShippingAddress) {
          const addr = this.tenantInformation

          addr.shipping_address_1 = addr.billing_address_1
          addr.shipping_address_2 = addr.billing_address_2
          addr.shipping_suburb = addr.billing_suburb
          addr.shipping_state = addr.billing_state
          addr.shipping_postal_code = addr.billing_postal_code
          addr.shipping_country = addr.billing_country
        }

        const createTenantResponse = await client.gateway.tenants.addTenants(
          this.tenantInformation
        )
        this.subscribeForm.tenant_uid = createTenantResponse.uid
        this.subscribeForm.country_code = this.tenantInformation.billing_country

        // Temporary: May need to allow variable quantity.
        this.subscribeForm.extras = this.additionalProducts.map((code) => {
          return { product_code: code, quantity: 1 }
        })

        const result = await client.gateway.billing.subscribe(
          this.subscribeForm
        )
        this.elementsOptions.clientSecret =
          result.provider_response.client_secret

        this.setCurrentTenant(createTenantResponse)

        this.setSubscription(result)
      } catch (error) {
        console.error(error)
      }
      this.loading = false
    },

    abandonConfirmation() {
      // client.gateway.billing.cancelSubscription()
      client.gateway.tenants.deleteTenant()
      this.subscribeForm.tenant_uid = null
      this.setSubscription(null)
      this.setCurrentTenant(null)
      this.elementsOptions.clientSecret = null
      this.step = 1
    },

    toDashCase
  },

  created() {
    window.addEventListener("beforeunload", this.unloadGuard)
  },

  mounted() {
    this.tenantInformation.billing_country = this.country
    this.fetchProducts(this.planCode)
  },

  beforeDestroy() {
    window.removeEventListener("beforeunload", this.unloadGuard)
  }
}
</script>
