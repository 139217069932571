<template>
  <v-container fluid>
    <div class="text-center">
      <div class="my-5">
        <h1 class="title-h1">Pricing &amp; Inclusions</h1>
      </div>
      <p>
        Nexus Connect Lab Edition offers a full suite of features and services
        to enable you to provide end-to-end full-arch restorative services to
        your clients.
      </p>
    </div>
    <v-row justify="center">
      <v-col
        cols="12"
        lg="3"
        md="6"
        v-for="(pricing, index) in plans"
        :key="index"
      >
        <PricingCard :pricing="pricing" @selected="planSelected" />
      </v-col>
    </v-row>
    <div class="text-center my-10" v-if="plans.length">
      Need more?
      <a href="/contact">Contact Sales</a>
      to discuss an
      <strong>Enterprise Plan</strong>
    </div>
    <v-row justify="center">
      <SignUp v-model="signUpActive" :selected-plan-code="selectedPlanCode" />
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex"
import { login } from "@/lib/authentication"
import PricingCard from "./PricingPage/PricingCard.vue"
import SignUp from "./PricingPage/SignUp.vue"

export default {
  name: "PricingPage",

  props: ["planCode"],

  components: {
    PricingCard,
    SignUp
  },

  data() {
    return {
      signUpActive: false,
      selectedPlanCode: null
    }
  },

  computed: {
    ...mapGetters([
      "plans",
      "loggedIn",
      "registered",
      "hasTenant",
      "activeSubscription"
    ])
  },

  methods: {
    planSelected(planCode) {
      if (this.registered) {
        this.$router.push({
          name: "registrationPage",
          params: {
            planCode: planCode
          }
        })
        return
      }
      if (this.loggedIn) {
        this.selectedPlanCode = planCode
        this.signUpActive = !this.signUpActive
        return
      }
      login({
        name: "pricingPagePlanSelected",
        params: {
          planCode: planCode
        }
      })
    }
  },

  mounted() {
    if (this.hasTenant && this.activeSubscription) {
      this.$router.push({
        name: "inboxPage"
      })
      return
    }
    if (this.planCode) {
      this.planSelected(this.planCode)
    }
  }
}
</script>
