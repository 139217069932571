<template>
  <v-container fluid fill-height class="d-flex align-start pt-0">
    <v-row class="mb-4 ml-4 title-font">
      <span class="font-weight-bold" v-if="currentQuotas">
        &nbsp;{{ kitLimit - kitQuotasRemaining }}&nbsp;
      </span>
      <div v-if="!currentQuotas" class="mr-2">
        <v-progress-circular
          indeterminate
          size="15"
          width="2"
          class="my-auto"
        />
      </div>
      of
      <span class="font-weight-bold"> &nbsp;{{ kitLimit }}&nbsp; </span>
      Scan Gauge Kits Registered
    </v-row>
    <v-row v-if="initialising">
      <v-col class="text-center">
        <v-progress-circular size="128" indeterminate />
      </v-col>
    </v-row>
    <v-row v-if="!initialising && kits.length === 0">
      <v-col class="text-center">
        You currently have no scan gauge kits associated to your account.
      </v-col>
    </v-row>
    <v-row v-if="!initialising">
      <v-col cols="2" v-for="(item, index) in items" :key="index">
        <ScanGaugeCard :item="item" />
      </v-col>
      <v-col
        cols="2"
        v-if="isOwner && (kitQuotasRemaining || upgradeable)"
        align="center"
      >
        <v-btn
          width="250px"
          min-height="250px"
          height="250px"
          class="rounded-10 elevation-0"
          style="text-transform: none"
          @click="handleOrder"
        >
          <template v-slot:default>
            <div class="d-flex flex-column" style="opacity: 0.6">
              <v-icon size="120" class="mb-2"> mdi-plus </v-icon>
              <span class="text-body-2"> Purchase another Scan Gauge Kit </span>
            </div>
          </template>
        </v-btn>
      </v-col>
    </v-row>
    <PurchaseDialog
      v-model="dialog"
      :item="lineItem"
      :loading="loading"
      @confirm="orderScanGaugeKit"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { deepEqual } from "@/utils"
import ScanGaugeCard from "./ScanGaugeKitsPage/ScanGaugeCard.vue"
import PurchaseDialog from "@/components/shared/PurchaseDialog.vue"
import client from "@/lib/ApiClient"

export default {
  name: "ScanGaugeKitsPage",

  components: { PurchaseDialog, ScanGaugeCard },

  data() {
    return {
      initialising: false,
      loading: false,
      dialog: false,
      invoice_uid: null,

      statusTranslations: [
        {
          status: "work_in_progress",
          description: "Manufacturing"
        },
        {
          status: "own_ready_for_dispatch",
          description: "Ready for Dispatch"
        },
        {
          status: "owned",
          description: "Dispatched"
        },
        {
          status: "faulty",
          description: "Faulty"
        }
      ]
    }
  },

  watch: {
    scanGaugeKits(val, prevVal) {
      if (val && !deepEqual(val, prevVal) && this.gaugeKitSalesOrders) {
        this.initialising = false
        this.loading = false
        if (this.dialog) this.dialog = false
      }
    },

    gaugeKitSalesOrders(val, prevVal) {
      if (val && !deepEqual(val, prevVal) && this.scanGaugeKits) {
        this.initialising = false
        this.loading = false
        if (this.dialog) this.dialog = false
      }
    }
  },

  computed: {
    ...mapGetters([
      "isOwner",
      "currentQuotas",
      "plans",
      "currentPlan",
      "products",
      "country",
      "subscription",
      "scanGaugeKits",
      "gaugeKitSalesOrders"
    ]),

    lineItem() {
      const product = this.products.find(
        (p) => p.product_code === "GAUGEKIT-GEN2"
      )
      return {
        description: "Scan Gauge Kit",
        quantity: 1,
        currency: this.currentPlan?.subscription_currency,
        price: product?.price,
        product_code: "GAUGEKIT-GEN2",
        quota_code: "registered_kits"
      }
    },

    items() {
      return [
        ...this.kits
          .filter(
            (kit) =>
              !this.salesOrders
                .flatMap((salesOrder) => salesOrder.kits)
                .includes(kit.uid)
          )
          .map((kit) => {
            return {
              ...kit,
              _type: "kit"
            }
          }),
        ...this.salesOrders.map((salesOrder) => {
          let kit = null
          if (salesOrder.kits.length > 0) {
            kit = this.kits.find((kit) => kit.uid === salesOrder.kits[0])
          }
          return {
            ...salesOrder,
            kit: kit,
            _type: "salesOrder"
          }
        })
      ]
    },

    kits() {
      if (!this.scanGaugeKits) return []
      return this.scanGaugeKits.filter((kit) => kit.status === "owned")
    },

    salesOrders() {
      if (!this.gaugeKitSalesOrders) return []
      return this.gaugeKitSalesOrders
    },

    kitQuotasRemaining() {
      const remaining_kit_quotas = this.currentQuotas?.find(
        (q) => q.quota_code === "registered_kits"
      )?.current

      return remaining_kit_quotas
    },

    kitLimit() {
      return this.currentQuotas?.find((q) => q.quota_code === "registered_kits")
        ?.incoming
    },

    upgradeable() {
      const upgradeable = this.plans.some(
        (p) => p.subscription_price > this.currentPlan.subscription_price
      )
      return upgradeable
    }
  },

  methods: {
    ...mapActions(["setCurrentQuotas", "triggerReload"]),

    handleOrder() {
      if (!this.kitQuotasRemaining)
        this.$router.push({ name: "profilePage", params: { change: true } })

      this.dialog = true
    },

    async poll() {
      if (!this.invoice_uid) return
      const invoice_paid = await client.gateway.billing.checkInvoice(
        this.invoice_uid
      )
      if (invoice_paid) {
        this.triggerReload("quotas")
        this.triggerReload("scanGaugeKits")
        this.triggerReload("gaugeKitSalesOrders")
      } else setTimeout(this.poll, 500)
    },

    async orderScanGaugeKit(paymentMethodId) {
      this.loading = true
      try {
        this.invoice_uid = await client.gateway.gaugeKits.orderScanGaugeKit(
          paymentMethodId
        )
      } catch {
        console.error("Failed to order Scan Gauge Kit.")
        this.loading = false
        return
      }
      this.poll()
    }
  },

  mounted() {
    if (this.scanGaugeKits === null || this.gaugeKitSalesOrders === null)
      this.initialising = true
    this.triggerReload("scanGaugeKits")
    this.triggerReload("gaugeKitSalesOrders")
  }
}
</script>
