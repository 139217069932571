<template>
  <v-hover v-slot="{ hover }">
    <div>
      <v-card
        height="175"
        width="175"
        :class="['rounded-10', 'blue-border', { 'btn-primary': hover }]"
        v-ripple
        @click="$emit('chooseOrder', 'DESIGN')"
      >
        <v-card-text
          class="px-1 text-center text-subtitle-1 pb-0"
          :style="{
            color: hover ? 'white' : '#147BD1'
          }"
        >
          <strong> MILLED BAR </strong>
        </v-card-text>
        <v-card-text
          class="pa-0 text-center font-weight-regular text-caption"
          :style="{
            color: hover ? 'white' : '#147BD1'
          }"
        >
          Your Tooth Design
        </v-card-text>
        <v-container fluid>
          <v-img
            contain
            :src="require('@/assets/milled-bar-only.png')"
            position="center center"
          />
        </v-container>
      </v-card>
      <v-card flat v-if="hover" width="250px">
        <v-card-text class="text-left">
          <ul>
            <li>
              Design and milling of a Nexus bar to your provided tooth-design.
            </li>
            <li>Start with abutment exports to begin design on lab-side.</li>
            <li>Requires final tooth design to begin bar design.</li>
            <li>Receive a milled bar and overlay file for milling.</li>
          </ul>
        </v-card-text>
      </v-card>
    </div>
  </v-hover>
</template>

<script>
export default {
  name: "BarOptions"
}
</script>
