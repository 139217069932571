import GatewayAPI from "./GatewayAPI"
import InstanceAPI from "./InstanceAPI"
import OftAPI from "./OftAPI"

class HttpClient {
  constructor() {
    this.gateway = new GatewayAPI()
    this.instance = new InstanceAPI()
    this.oft = new OftAPI()
  }

  setTenantUid(uid) {
    this.gateway.setTenantUid(uid)
    this.instance.setTenantUid(uid)
    this.tenantUid = uid
  }

  setInstanceUrl(url) {
    this.instance.setInstanceUrl(url)
    this.instanceUrl = url
  }
}

var client = new HttpClient()

export default client
