<template>
  <div>
    <OpenForwardedOrders @openOrder="handleOrderClick" class="mb-4 mt-4" />
    <v-card flat class="ma-4 ml-0 rounded-20 overflow-hidden">
      <template v-if="partialOrders.length">
        <v-card-title class="title-font">
          PARTIALLY COMPLETE ORDERS
        </v-card-title>
        <v-card-text> Click on an order to continue. </v-card-text>
        <v-simple-table v-if="partialOrders.length">
          <template v-slot:default>
            <tbody>
              <tr v-for="order in partialOrders" :key="order.uid">
                <td>
                  <v-icon size="35" class="mr-2">
                    {{ determineIconFromProduct(order.product) }}
                  </v-icon>
                  {{ order.product }}
                </td>
                <td>
                  {{ order.created_at | luxon({ output: "short" }) }}
                </td>
                <td class="text-right">
                  <v-btn
                    class="btn-error mr-2"
                    small
                    @click="deleteOrder(order)"
                  >
                    Delete
                  </v-btn>
                  <v-btn
                    class="btn-primary mr-2"
                    small
                    @click="handleOrderClick(order)"
                  >
                    Continue
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </template>
    </v-card>
  </div>
</template>

<script>
import client from "@/lib/ApiClient"
import { mapGetters, mapActions } from "vuex"
import OpenForwardedOrders from "@/components/shared/OpenForwardedOrders"

export default {
  name: "ExistingAndPartialOrders",

  components: {
    OpenForwardedOrders
  },

  computed: {
    ...mapGetters(["selectedOrder", "orderForwardedOrders"]),

    partialOrders() {
      return this.orderForwardedOrders.filter((o) => !o.submitted_at) || []
    }
  },

  methods: {
    ...mapActions(["setSelectedForwardedOrder", "triggerReload"]),

    determineIconFromProduct(product_code) {
      switch (product_code) {
        case "BAR":
          return "$milledbar"
        case "DESIGN":
          return "$toothdesigns"
      }
    },

    async handleOrderClick(order) {
      this.setSelectedForwardedOrder(order)
      this.triggerReload("forwardedOrder")
    },

    async deleteOrder(order) {
      await client.instance.orders.deleteForwardedOrder(
        this.selectedOrder.uid,
        order.uid
      )
      this.triggerReload("forwardedOrders")
    }
  }
}
</script>
