<template>
  <v-card flat>
    <v-card-title>
      <v-icon class="mr-2">mdi-web</v-icon>
      Choose Domain
    </v-card-title>
    <v-card-text>
      <v-text-field
        v-model="slug"
        label="Domain"
        :prefix="orderingBaseURI"
        outlined
        :error-messages="errorMessage"
        dense
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { debounce } from "@/utils"
import client from "@/lib/ApiClient"
import { mapGetters, mapActions } from "vuex"

export default {
  name: "DomainSelector",

  props: ["value"],

  inject: ["form"],

  data() {
    return {
      slugAvailable: true
    }
  },

  watch: {
    slug(slug) {
      if (!slug) return
      this.debouncedSlugCheck()
    },

    slugAvailable(val) {
      if (!val) {
        const slugAtChange = this.slug
        setTimeout(() => {
          if (slugAtChange === this.slug)
            this.slug = this.slug + String(Math.floor(Math.random() * 10 + 1))
        }, 1000)
      }
    }
  },

  computed: {
    ...mapGetters(["orderingBaseURI"]),

    slug: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit("input", value)
      }
    },
    errorMessage() {
      if (!this.slug) return "Cannot be empty"
      return this.slugAvailable ? null : "This one's already taken"
    }
  },

  methods: {
    ...mapActions(["fetchBaseOrderingURI"]),

    validate() {
      return !this.errorMessage
    },

    async handleSlugCheck() {
      const result = await client.gateway.tenants.checkSlug(this.slug)
      this.slugAvailable = !result
    }
  },

  created() {
    this.form.register(this)
    this.fetchBaseOrderingURI()
    this.debouncedSlugCheck = debounce(this.handleSlugCheck, 500)
  }
}
</script>
