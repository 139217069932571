<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    content-class="rounded-20"
    :persistent="loading"
  >
    <v-card>
      <v-card-title class="text-h5">
        {{
          subscription.auto_renew
            ? "Cancel Subscription Renewal?"
            : "Enable Subscription Renewal?"
        }}
        <v-progress-circular
          indeterminate
          size="32"
          v-if="loading"
          class="ml-5"
        />
        <v-spacer />
        <v-btn icon @click="dialog = false" :disabled="loading">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <p v-if="!currentTenant.activated" class="black--text">
        <strong> This action will activate your Subscription. </strong>
      </p>
      <v-card-text v-if="subscription.auto_renew">
        This will cancel your subscription at the end of the billing period.
      </v-card-text>
      <v-card-text v-if="!subscription.auto_renew">
        This will enable renewal of your subscription at the end of each billing
        period.
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn class="btn-primary" @click="toggleAutoRenew" :disabled="loading">
          {{
            subscription.auto_renew
              ? "Schedule Cancellation"
              : "Re-Enable Renewal"
          }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex"
import client from "@/lib/ApiClient"

export default {
  name: "CancelDialog",

  props: ["value"],

  data() {
    return {
      loading: false
    }
  },

  computed: {
    ...mapGetters(["subscription", "currentTenant"]),

    dialog: {
      get() {
        return this.value
      },

      set(payload) {
        return this.$emit("input", payload)
      }
    }
  },

  methods: {
    ...mapActions(["setSubscription"]),

    async toggleAutoRenew() {
      this.loading = true
      const subscription = await client.gateway.billing.toggleAutoRenew()
      this.setSubscription(subscription)

      this.loading = false
      this.dialog = false
    }
  }
}
</script>
